import React from 'react';

const Branch = ({ branch, setBranch }) => {
  return (
    <div className="bg-white rounded-lg p-5 shadow-md">
      <h1 className="font-bold text-[20px]">Branch Information</h1>
      <div className="flex flex-wrap mt-5">
        <div className="flex flex-col mt-2 font-medium">
          <p className="my-1">Cabang Distributor</p>
          <p className="my-1">Alamat Email</p>
          <p className="my-1">Alamat</p>
        </div>
        <div className="flex flex-col mt-2 ml-[50px]">
          <p className="my-1">:</p>
          <p className="my-1">:</p>
          <p className="my-1">:</p>
        </div>

        {branch.map((item, index) => {
          return (
            <div className="flex flex-col mt-2 ml-10" key={index}>
              <p className="my-1">{item.cabang_nama}</p>
              <p className="my-1">{item.email}</p>
              <p className="my-1">{item.alamat}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Branch;
