import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Popup from "reactjs-popup";
import {
  putStaffSipa,
  putStaffExpSipa,
  putStaffKtpFile,
  putStaffSipaFile,
  putStaffTandaTangan,
} from "../API";
import { FiEdit } from "react-icons/fi";
import {
  BsCheckCircleFill,
  BsPaperclip,
  BsFillFilePdfFill,
} from "react-icons/bs";
import ModalStaff from "../common/ModalStaff";

const Staff = (props) => {
  const { companys, staff } = props;

  const queryClient = useQueryClient();
  const [staffId, setStaffId] = useState(null);
  const [showModalSipa, setShowModalSipa] = useState(false);
  const [showModalSipaExpired, setShowModalSipaExpired] = useState(false);
  const [showModalSipaKtp, setShowModalSipaKtp] = useState(false);
  const [showModalSipaFile, setShowModalSipaFile] = useState(false);
  const [showModalSipaEsign, setShowModalSipaEsign] = useState(false);
  const [sipa, setSipa] = useState("");

  const { mutate: mutateUpdateSipa } = useMutation({
    mutationFn: putStaffSipa,
    onSuccess: () => {
      setShowModalSipa(false);
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
    },
  });

  const { mutate: mutateUpdateSipaExp } = useMutation({
    mutationFn: putStaffExpSipa,
    onSuccess: () => {
      setShowModalSipaExpired(false);
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
    },
  });

  const { mutate: mutateUpdateSipaFile } = useMutation({
    mutationFn: putStaffSipaFile,
    onSuccess: () => {
      setShowModalSipaFile(false);
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
    },
  });

  const { mutate: mutateUpdateSipaKtpFile } = useMutation({
    mutationFn: putStaffKtpFile,
    onSuccess: () => {
      setShowModalSipaKtp(false);
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
    },
  });

  const { mutate: mutateUpdateEsignFile } = useMutation({
    mutationFn: putStaffTandaTangan,
    onSuccess: () => {
      setShowModalSipaEsign(false);
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
    },
  });

  return (
    <>
      <div className="bg-white rounded-lg p-5 shadow-md">
        <h1 className="font-bold text-[20px]">Staff Information</h1>
        {staff && staff !== undefined
          ? staff.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ModalStaff
                    showModal={showModalSipa}
                    setShowModal={setShowModalSipa}
                    defaultValue={item.no_sipa_keterangan}
                    companys={companys}
                    staffId={staffId}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateSipa}
                  />

                  <ModalStaff
                    showModal={showModalSipaExpired}
                    setShowModal={setShowModalSipaExpired}
                    defaultValue={item.expired_sipa_keterangan}
                    companys={companys}
                    staffId={staffId}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateSipaExp}
                  />

                  <ModalStaff
                    showModal={showModalSipaKtp}
                    setShowModal={setShowModalSipaKtp}
                    defaultValue={item.ktp_file_keterangan}
                    companys={companys}
                    staffId={staffId}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateSipaKtpFile}
                  />

                  <ModalStaff
                    showModal={showModalSipaFile}
                    setShowModal={setShowModalSipaFile}
                    defaultValue={item.sipa_file_keterangan}
                    companys={companys}
                    staffId={staffId}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateSipaFile}
                  />

                  <ModalStaff
                    showModal={showModalSipaEsign}
                    setShowModal={setShowModalSipaEsign}
                    defaultValue={item.tanda_tangan_keterangan}
                    companys={companys}
                    staffId={staffId}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateEsignFile}
                  />

                  {item.jenis_pekerja === "Kasir" ||
                  item.jenis_pekerja === "Finance" ||
                  item.jenis_pekerja === "Admin" ? null : (
                    <div className="collapse collapse-arrow border shadow-md rounded-lg mt-5">
                      <input type="checkbox" className="peer" />
                      <div className="collapse-title font-semibold">
                        {item.nama_pekerja}
                      </div>
                      <div className="collapse-content">
                        <div className="flex flex-wrap mt-5">
                          <div className="flex flex-col text-black">
                            <p className="my-1">Nama Pekerja</p>
                            <p className="my-1">Jenis Staff</p>
                            <p className="my-1">No SIPA</p>
                            <p className="my-1 mt-[25px]">Masa Berlaku SIPA</p>
                            <p className="my-1">No HP Apoteker</p>
                            <p className="my-1">Alamat Email Apoteker</p>
                          </div>
                          <div className="flex flex-col text-black  ml-[50px]">
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1 mt-[25px]">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                          </div>
                          <div className="flex flex-col text-black ml-10">
                            <p className="my-1">{item.nama_pekerja}</p>
                            <p className="my-1">{item.jenis_pekerja}</p>

                            <div className="flex flex-wrap justify-between items-center gap-52">
                              <p className="my-1">
                                {item.no_sipa !== null ? item.no_sipa : ""}
                              </p>

                              {item.no_sipa_status === 1 && (
                                <div className="flex items-center gap-3">
                                  <div>
                                    <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                  </div>
                                  <FiEdit
                                    className="text-[20px] hover:text-blue-500 cursor-pointer"
                                    onClick={() => {
                                      setShowModalSipa(true);
                                      setStaffId(item.id);
                                      setSipa(item.no_sipa_keterangan);
                                    }}
                                  />
                                </div>
                              )}

                              {item.no_sipa_status === 2 && (
                                <div className="flex items-center gap-3">
                                  <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                    {item.no_sipa_keterangan}
                                  </p>
                                  <FiEdit
                                    className="text-[20px] hover:text-blue-500 cursor-pointer"
                                    onClick={() => {
                                      setShowModalSipa(true);
                                      setStaffId(item.id);
                                      setSipa(item.no_sipa_keterangan);
                                    }}
                                  />
                                </div>
                              )}
                              {item.no_sipa_status === 3 && (
                                <div className="grid grid-cols-2 gap-3">
                                  <button
                                    className="p-1 bg-green-500 text-white rounded-lg px-5"
                                    onClick={() => {
                                      mutateUpdateSipa({
                                        status: 1,
                                        keterangan: "OK",
                                        company_id: companys,
                                        staff_id: item.id,
                                      });
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="p-1 bg-red-500 text-white rounded-lg px-5"
                                    onClick={() => {
                                      setStaffId(item.id);
                                      setShowModalSipa(true);
                                      setSipa("");
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}
                            </div>

                            <div className="flex flex-wrap justify-between items-center gap-52 mt-[7px]">
                              <p className="my-1">
                                {item.expired_sipa !== null
                                  ? item.expired_sipa.slice(0, 10)
                                  : ""}
                              </p>
                              {item.expired_sipa_status === 1 && (
                                <div className="flex items-center gap-3">
                                  <div>
                                    <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                  </div>
                                  <FiEdit
                                    className="text-[20px] hover:text-blue-500 cursor-pointer"
                                    onClick={() => {
                                      setShowModalSipaExpired(true);
                                      setStaffId(item.id);
                                      setSipa(item.expired_sipa_keterangan);
                                    }}
                                  />
                                </div>
                              )}

                              {item.expired_sipa_status === 2 && (
                                <div className="flex items-center gap-3">
                                  <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                    {item.expired_sipa_keterangan}
                                  </p>
                                  <FiEdit
                                    className="text-[20px] hover:text-blue-500 cursor-pointer"
                                    onClick={() => {
                                      setShowModalSipaExpired(true);
                                      setStaffId(item.id);
                                      setSipa(item.expired_sipa_keterangan);
                                    }}
                                  />
                                </div>
                              )}
                              {item.expired_sipa_status === 3 && (
                                <div className="grid grid-cols-2 gap-3">
                                  <button
                                    className="p-1 bg-green-500 text-white rounded-lg px-5"
                                    onClick={() => {
                                      mutateUpdateSipaExp({
                                        status: 1,
                                        keterangan: "OK",
                                        company_id: companys,
                                        staff_id: item.id,
                                      });
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="p-1 bg-red-500 text-white rounded-lg px-5"
                                    onClick={() => {
                                      setStaffId(item.id);
                                      setShowModalSipaExpired(true);
                                      setSipa("");
                                    }}
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}
                            </div>

                            <p className="my-1">{item.telepon_staff}</p>
                            <p className="my-1">{item.email}</p>
                          </div>
                        </div>
                        <div className="border rounded-lg p-5 mt-3">
                          <h1 className=" font-bold text-[20px]">
                            Kelengkapan Dokumen
                          </h1>
                          <table className="table mt-5">
                            <tbody>
                              <tr className="">
                                <th>KTP Staff</th>
                                <td>:</td>
                                <td>
                                  <div className="rounded transform  duration-200 hover:scale-110">
                                    {item.ktp_file.endsWith(".png") ||
                                    item.ktp_file.endsWith(".jpeg") ||
                                    item.ktp_file.endsWith(".jpg") ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={item.ktp_file}
                                            alt=""
                                            className="w-24"
                                          />
                                        }
                                        modal
                                      >
                                        <img
                                          src={item.ktp_file}
                                          alt=""
                                          className="w-max"
                                        />
                                      </Popup>
                                    ) : (
                                      <div className="flex justify-center items-center">
                                        <a
                                          href={item.ktp_file}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <BsFillFilePdfFill className="text-[30px]" />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="grid grid-cols-2 gap-3 mt-2">
                                    {item.ktp_file_status === 1 && (
                                      <div className="flex items-center gap-3">
                                        <div>
                                          <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                        </div>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaKtp(true);
                                            setStaffId(item.id);
                                            setSipa(item.ktp_file_keterangan);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {item.ktp_file_status === 2 && (
                                      <div className="flex items-center gap-3">
                                        <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                          {item.ktp_file_keterangan}
                                        </p>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaKtp(true);
                                            setStaffId(item.id);
                                            setSipa(item.ktp_file_keterangan);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {item.ktp_file_status === 3 && (
                                      <div className="grid grid-cols-2 gap-3">
                                        <button
                                          className="p-1 bg-green-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            mutateUpdateSipaKtpFile({
                                              status: 1,
                                              keterangan: "OK",
                                              company_id: companys,
                                              staff_id: item.id,
                                            });
                                          }}
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="p-1 bg-red-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            setStaffId(item.id);
                                            setShowModalSipaKtp(true);
                                            setSipa("");
                                          }}
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}{" "}
                                  </div>
                                </td>
                              </tr>
                              <tr className="">
                                <th>
                                  Surat Izin Praktek Apoteker / Tenaga Teknis
                                </th>
                                <td>:</td>
                                <td>
                                  <div className="rounded transform  duration-200 hover:scale-110">
                                    {item.sipa_file.endsWith(".png") ||
                                    item.sipa_file.endsWith(".jpg") ||
                                    item.sipa_file.endsWith(".jpeg") ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={item.sipa_file}
                                            alt=""
                                            className="w-24"
                                          />
                                        }
                                        modal
                                      >
                                        <img
                                          src={item.sipa_file}
                                          alt=""
                                          className="w-max"
                                        />
                                      </Popup>
                                    ) : (
                                      <div className="flex justify-center items-center">
                                        <a
                                          href={item.sipa_file}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <BsFillFilePdfFill className="text-[30px]" />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="grid grid-cols-2 gap-3 mt-2">
                                    {item.sipa_file_status === 1 && (
                                      <div className="flex items-center gap-3">
                                        <div>
                                          <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                        </div>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaFile(true);
                                            setStaffId(item.id);
                                            setSipa(item.sipa_file_keterangan);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {item.sipa_file_status === 2 && (
                                      <div className="flex items-center gap-3">
                                        <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                          {item.sipa_file_keterangan}
                                        </p>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaFile(true);
                                            setStaffId(item.id);
                                            setSipa(item.sipa_file_keterangan);
                                          }}
                                        />
                                      </div>
                                    )}
                                    {item.sipa_file_status === 3 && (
                                      <div className="grid grid-cols-2 gap-3">
                                        <button
                                          className="p-1 bg-green-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            mutateUpdateSipaFile({
                                              status: 1,
                                              keterangan: "OK",
                                              company_id: companys,
                                              staff_id: item.id,
                                            });
                                          }}
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="p-1 bg-red-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            setStaffId(item.id);
                                            setShowModalSipaFile(true);
                                            setSipa("");
                                          }}
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr className="">
                                <th>Upload Spesimen Tanda Tangan</th>
                                <td>:</td>
                                <td>
                                  <div className="rounded transform  duration-200 hover:scale-110">
                                    {item.tanda_tangan.endsWith(".png") ||
                                    item.tanda_tangan.endsWith(".jpg") ||
                                    item.tanda_tangan.endsWith(".jpeg") ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={item.tanda_tangan}
                                            alt=""
                                            className="w-24"
                                          />
                                        }
                                        modal
                                      >
                                        <img
                                          src={item.tanda_tangan}
                                          alt=""
                                          className="w-max"
                                        />
                                      </Popup>
                                    ) : (
                                      <div className="flex justify-center items-center">
                                        <a
                                          href={item.tanda_tangan}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <BsFillFilePdfFill className="text-[30px]" />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div className="grid grid-cols-2 gap-3 mt-2">
                                    {item.tanda_tangan_status === 1 && (
                                      <div className="flex items-center gap-3">
                                        <div>
                                          <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                        </div>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaEsign(true);
                                            setStaffId(item.id);
                                            setSipa(
                                              item.tanda_tangan_keterangan
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                    {item.tanda_tangan_status === 2 && (
                                      <div className="flex items-center gap-3">
                                        <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                          {item.tanda_tangan_keterangan}
                                        </p>
                                        <FiEdit
                                          className="text-[20px] hover:text-blue-500 cursor-pointer"
                                          onClick={() => {
                                            setShowModalSipaEsign(true);
                                            setStaffId(item.id);
                                            setSipa(
                                              item.tanda_tangan_keterangan
                                            );
                                          }}
                                        />
                                      </div>
                                    )}

                                    {item.tanda_tangan_status === 3 && (
                                      <div className="grid grid-cols-2 gap-3">
                                        <button
                                          className="p-1 bg-green-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            mutateUpdateEsignFile({
                                              status: 1,
                                              keterangan: "OK",
                                              company_id: companys,
                                              staff_id: item.id,
                                            });
                                          }}
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="p-1 bg-red-500 text-white rounded-lg px-5"
                                          onClick={() => {
                                            setShowModalSipaEsign(true);
                                            setStaffId(item.id);
                                            setSipa("");
                                          }}
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })
          : null}
      </div>
    </>
  );
};

export default Staff;
