import React from "react";
import Waiting from "../../assets/waiting.jpg";

const ModalStaff = (props) => {
  const {
    showModal,
    setShowModal,
    sipa,
    setSipa,
    mutateUpdateStaff,
    companys,
    staffId,
  } = props;
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="flex justify-center p-6 flex-auto">
                  <img
                    src={Waiting}
                    alt=""
                    className=" justify-center w-[200px]"
                  />
                </div>
                {/*footer*/}

                <p className="text-center font-medium text-[20px] mb-3 p-5">
                  Masukan alasan mengapa kamu menolak <br /> pesanan terkait di
                  bawah ini
                </p>
                <textarea
                  className="textarea textarea-bordered h-32 mx-5 mb-4"
                  onChange={(e) => setSipa(e.target.value)}
                  value={sipa}
                ></textarea>
                <div className="flex items-center justify-center p-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Batal
                  </button>
                  {sipa === "OK" || sipa === "" || sipa === "ok" ? (
                    <button
                      className="bg-green-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        mutateUpdateStaff({
                          status: 1,
                          keterangan: "OK",
                          company_id: companys,
                          staff_id: staffId,
                        });
                      }}
                    >
                      Accept
                    </button>
                  ) : (
                    <button
                      className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        mutateUpdateStaff({
                          status: 2,
                          keterangan: sipa,
                          company_id: companys,
                          staff_id: staffId,
                        });
                      }}
                    >
                      Reject
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalStaff;
