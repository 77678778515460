import React from "react";
import VerifyBmMpi from "./pages/VerifyBmMpi";
import { Routes, Route, Navigate } from "react-router-dom";
import EndVerifyPage from "./pages/EndPPage";
import VerifyBmNewUser from "./pages/VerifyBmNewUser";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/verify-bm-existing/data" />} />
        <Route path="/verify-bm-existing/data" element={<VerifyBmMpi />} />
        <Route path="/verify-bm-clear" element={<EndVerifyPage />} />
        <Route
          path="/verify-bm-new-apotek/data"
          element={<VerifyBmNewUser />}
        />
      </Routes>
    </>
  );
}

export default App;
