import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FiEdit } from "react-icons/fi";
import { BsCheckCircleFill, BsFillFilePdfFill } from "react-icons/bs";
import {
  putCompanyNIB,
  putCompanySius,
  putCompanyExpiredSius,
  putCompanyNPWP,
  putCompanyIzin,
  putCompanyStempel,
  putCompanyNibFile,
} from "../API";
import { Document, Page, pdfjs } from "react-pdf";
import { BsPaperclip } from "react-icons/bs";

import ModalCompany from "../common/ModalCompany";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Company = (props) => {
  const queryClient = useQueryClient();
  const { company, companys } = props;
  const [sipa, setSipa] = useState("");

  const [showModalNib, setShowModalNib] = useState(false);
  const [showModalSius, setShowModalSius] = useState(false);
  const [showModalExpSius, setShowModalExpSius] = useState(false);
  const [showModalNPWP, setShowModalNPWP] = useState(false);
  const [showModalSiusFile, setShowModalSiusFile] = useState(false);
  const [showModalStempel, setShowModalStempel] = useState(false);
  const [showModalNibFile, setShowModalNibFile] = useState(false);

  const { mutate: mutateUpdateNib } = useMutation({
    mutationFn: putCompanyNIB,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNib(false);
    },
  });

  const { mutate: mutateUpdateSius } = useMutation({
    mutationFn: putCompanySius,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalSius(false);
    },
  });

  const { mutate: mutateUpdateExpSius } = useMutation({
    mutationFn: putCompanyExpiredSius,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalExpSius(false);
    },
  });

  const { mutate: mutateUpdateNpwp } = useMutation({
    mutationFn: putCompanyNPWP,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNPWP(false);
    },
  });

  const { mutate: mutateUpdateSiusFile } = useMutation({
    mutationFn: putCompanyIzin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalSiusFile(false);
    },
  });

  const { mutate: mutateUpdateStempelFile } = useMutation({
    mutationFn: putCompanyStempel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalStempel(false);
    },
  });

  const { mutate: mutateUpdateNibFile } = useMutation({
    mutationFn: putCompanyNibFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNibFile(false);
    },
  });

  return (
    <div className="bg-white rounded-lg p-5 shadow-md">
      <h1 className="font-bold text-[20px]">Company Information</h1>
      {company && company !== undefined
        ? company.map((item, index) => (
            <>
              <div className="flex mt-5">
                <div className="flex flex-col mt-2 font-medium">
                  <p className="my-1">Bidang Usaha</p>
                  <p className="my-1">Company Name</p>
                  <p className="my-1">Alamat Sesuai NPWP</p>
                  <p className="my-1">Alamat Usaha</p>
                  <p className="my-1">No HP Sarana</p>
                  <p className="my-1">NIB</p>
                  <p className="my-1">Kode SIPNAP</p>
                  <p className="my-1">Surat Izin Usaha</p>
                  <p className="my-3">Masa Berlaku Surat Izin</p>
                  <p className="my-1">No Rekening Apotek</p>
                  <p className="my-1">Kode Bank Address</p>
                  <p className="my-1">Nama Pemilik Rekening</p>
                </div>
                <div className="flex flex-col mt-2 ml-[50px]">
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-1">:</p>
                  <p className="my-3">:</p>
                  <p className="mt-1">:</p>
                  <p className="mt-1">:</p>
                  <p className="mt-3">:</p>
                </div>
                <>
                  <div className="flex flex-col mt-2  ml-10" key={index}>
                    <ModalCompany
                      showModal={showModalNib}
                      setShowModal={setShowModalNib}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateNib}
                    />

                    <ModalCompany
                      showModal={showModalSius}
                      setShowModal={setShowModalSius}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateSius}
                    />

                    <ModalCompany
                      showModal={showModalExpSius}
                      setShowModal={setShowModalExpSius}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateExpSius}
                    />

                    <ModalCompany
                      showModal={showModalNPWP}
                      setShowModal={setShowModalNPWP}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateNpwp}
                    />

                    <ModalCompany
                      showModal={showModalSiusFile}
                      setShowModal={setShowModalSiusFile}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateSiusFile}
                    />

                    <ModalCompany
                      showModal={showModalStempel}
                      setShowModal={setShowModalStempel}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateStempelFile}
                    />

                    <ModalCompany
                      showModal={showModalNibFile}
                      setShowModal={setShowModalNibFile}
                      companys={companys}
                      setSipa={setSipa}
                      sipa={sipa}
                      mutateUpdateStaff={mutateUpdateNibFile}
                    />

                    <p className="my-1">{item.bidang_usahaa}</p>
                    <p className="my-1">{item.nama_perusahaan}</p>
                    <p className="my-1">{item.alamat_npwp}</p>
                    <p className="my-1">{item.alamat_sarana}</p>
                    <p className="my-1">{item.telepon}</p>

                    <div className="flex flex-wrap justify-between items-center gap-52">
                      <p className="my-1">{item.nib}</p>

                      {item.nib_status === 1 && (
                        <div className="flex items-center gap-3">
                          <div>
                            <BsCheckCircleFill className="text-green-500 text-[20px]" />
                          </div>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalNib(true);
                              setSipa(item.nib_keterangan);
                            }}
                          />
                        </div>
                      )}

                      {item.nib_status === 2 && (
                        <div className="flex items-center gap-3">
                          <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                            {item.nib_keterangan}
                          </p>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalNib(true);
                              setSipa(item.nib_keterangan);
                            }}
                          />
                        </div>
                      )}
                      {item.nib_status === 3 && (
                        <div className="grid grid-cols-2 gap-3">
                          <button
                            className="p-1 bg-green-500 text-white rounded-lg px-5"
                            onClick={() => {
                              mutateUpdateNib({
                                status: 1,
                                keterangan: "OK",
                                company_id: companys,
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="p-1 bg-red-500 text-white rounded-lg px-5"
                            onClick={() => {
                              setShowModalNib(true);
                              setSipa("");
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>

                    <p className="my-1">{item.kode_sipnap}</p>

                    <div className="flex flex-wrap justify-between items-center gap-52">
                      <p className="my-1">{item.surat_izin_usaha_sarana}</p>
                      {item.sius_status === 1 && (
                        <div className="flex items-center gap-3">
                          <div>
                            <BsCheckCircleFill className="text-green-500 text-[20px]" />
                          </div>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalSius(true);
                              setSipa(item.sius_keterangan);
                            }}
                          />
                        </div>
                      )}

                      {item.sius_status === 2 && (
                        <div className="flex items-center gap-3">
                          <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                            {item.sius_keterangan}
                          </p>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalSius(true);
                              setSipa(item.sius_keterangan);
                            }}
                          />
                        </div>
                      )}
                      {item.sius_status === 3 && (
                        <div className="grid grid-cols-2 gap-3">
                          <button
                            className="p-1 bg-green-500 text-white rounded-lg px-5"
                            onClick={() => {
                              mutateUpdateSius({
                                status: 1,
                                keterangan: "OK",
                                company_id: companys,
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="p-1 bg-red-500 text-white rounded-lg px-5"
                            onClick={() => {
                              setShowModalSius(true);
                              setSipa("");
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="flex flex-wrap justify-between items-center gap-52 mt-2">
                      <p className="my-1">{item.masa_berlaku_surat_izin}</p>
                      {item.exp_sius_status === 1 && (
                        <div className="flex items-center gap-3">
                          <div>
                            <BsCheckCircleFill className="text-green-500 text-[20px]" />
                          </div>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalExpSius(true);
                              setSipa(item.exp_sius_keterangan);
                            }}
                          />
                        </div>
                      )}

                      {item.exp_sius_status === 2 && (
                        <div className="flex items-center gap-3">
                          <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                            {item.exp_sius_keterangan}
                          </p>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalExpSius(true);
                              setSipa(item.exp_sius_keterangan);
                            }}
                          />
                        </div>
                      )}
                      {item.exp_sius_status === 3 && (
                        <div className="grid grid-cols-2 gap-3">
                          <button
                            className="p-1 bg-green-500 text-white rounded-lg px-5"
                            onClick={() => {
                              mutateUpdateExpSius({
                                status: 1,
                                keterangan: "OK",
                                company_id: companys,
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="p-1 bg-red-500 text-white rounded-lg px-5"
                            onClick={() => {
                              setShowModalExpSius(true);
                              setSipa("");
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>

                    <p className="mt-3 mb-1">{item.no_rekening}</p>
                    <p className="my-1">{item.bank}</p>
                    <p className="my-1">{item.nama_pemilik_rekening}</p>
                  </div>
                </>
              </div>
              <table className="mt-5">
                <h1 className="mt-10 font-bold text-[20px]">
                  Kelengkapan Dokumen
                </h1>
                <>
                  <tbody>
                    <tr className="">
                      <th>NPWP Perusahaan</th>
                      <td>:</td>
                      <td>
                        <div className="rounded transform duration-200 hover:scale-110">
                          {item.npwp_file.endsWith(".png") ||
                          item.npwp_file.endsWith(".jpg") ||
                          item.npwp_file.endsWith(".jpeg") ? (
                            <Popup
                              trigger={
                                <img
                                  src={item.npwp_file}
                                  alt=""
                                  className="w-24"
                                />
                              }
                              modal
                            >
                              <img
                                src={item.npwp_file}
                                alt=""
                                className="w-full"
                              />
                            </Popup>
                          ) : (
                            <div className="flex justify-center items-center">
                              <a
                                href={item.npwp_file}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <BsFillFilePdfFill className="text-[30px]" />
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="grid grid-cols-2 gap-3 mt-2">
                          {item.npwp_file_status === 1 && (
                            <div className="flex items-center gap-3">
                              <div>
                                <BsCheckCircleFill className="text-green-500 text-[20px]" />
                              </div>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalNPWP(true);
                                  setSipa(item.npwp_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.npwp_file_status === 2 && (
                            <div className="flex items-center gap-3">
                              <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                {item.npwp_file_keterangan}
                              </p>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalNPWP(true);
                                  setSipa(item.npwp_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.npwp_file_status === 3 && (
                            <div className="grid grid-cols-2 gap-3">
                              <button
                                className="p-1 bg-green-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  mutateUpdateNpwp({
                                    status: 1,
                                    keterangan: "OK",
                                    company_id: companys,
                                  });
                                }}
                              >
                                Accept
                              </button>
                              <button
                                className="p-1 bg-red-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  setShowModalNPWP(true);
                                  setSipa("");
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <th>Izin Sarana</th>
                      <td>:</td>
                      <td>
                        <div className="rounded transform  duration-200 hover:scale-110">
                          {item.sius_file.endsWith(".png") ||
                          item.sius_file.endsWith(".jpg") ||
                          item.sius_file.endsWith(".jpeg") ? (
                            <Popup
                              trigger={
                                <img
                                  src={item.sius_file}
                                  alt=""
                                  className="w-24"
                                />
                              }
                              modal
                            >
                              <img
                                src={item.sius_file}
                                alt=""
                                className="w-full"
                              />
                            </Popup>
                          ) : (
                            <div className="flex justify-center items-center">
                              <a
                                href={item.sius_file}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <BsFillFilePdfFill className="text-[30px]" />
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="grid grid-cols-2 gap-3 mt-2">
                          {item.sius_file_status === 1 && (
                            <div className="flex items-center gap-3">
                              <div>
                                <BsCheckCircleFill className="text-green-500 text-[20px]" />
                              </div>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalSiusFile(true);
                                  setSipa(item.sius_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.sius_file_status === 2 && (
                            <div className="flex items-center gap-3">
                              <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                {item.sius_file_keterangan}
                              </p>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalSiusFile(true);
                                  setSipa(item.sius_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.sius_file_status === 3 && (
                            <div className="grid grid-cols-2 gap-3">
                              <button
                                className="p-1 bg-green-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  mutateUpdateSiusFile({
                                    status: 1,
                                    keterangan: "OK",
                                    company_id: companys,
                                  });
                                }}
                              >
                                Accept
                              </button>
                              <button
                                className="p-1 bg-red-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  setShowModalSiusFile(true);
                                  setSipa("");
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr className="">
                      <th>Stempel Sarana</th>
                      <td>:</td>
                      <td>
                        <div className="rounded transform  duration-200 hover:scale-110">
                          {item.stempel_file_sarana.endsWith(".png") ||
                          item.stempel_file_sarana.endsWith(".jpg") ||
                          item.stempel_file_sarana.endsWith(".png") ? (
                            <Popup
                              trigger={
                                <img
                                  src={item.stempel_file_sarana}
                                  alt=""
                                  className="w-24"
                                />
                              }
                              modal
                            >
                              <img
                                src={item.stempel_file_sarana}
                                alt=""
                                className="w-full"
                              />
                            </Popup>
                          ) : (
                            <div className="flex justify-center items-center">
                              <a
                                href={item.stempel_file_sarana}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <BsFillFilePdfFill className="text-[30px]" />
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="grid grid-cols-2 gap-3 mt-2">
                          {item.stempel_file_status === 1 && (
                            <div className="flex items-center gap-3">
                              <div>
                                <BsCheckCircleFill className="text-green-500 text-[20px]" />
                              </div>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalStempel(true);
                                  setSipa(item.stempel_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.stempel_file_status === 2 && (
                            <div className="flex items-center gap-3">
                              <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                {item.stempel_file_keterangan}
                              </p>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalStempel(true);
                                  setSipa(item.stempel_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.stempel_file_status === 3 && (
                            <div className="grid grid-cols-2 gap-3">
                              <button
                                className="p-1 bg-green-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  mutateUpdateStempelFile({
                                    status: 1,
                                    keterangan: "OK",
                                    company_id: companys,
                                  });
                                }}
                              >
                                Accept
                              </button>
                              <button
                                className="p-1 bg-red-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  setShowModalStempel(true);
                                  setSipa("");
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <th>NIB</th>
                      <td>:</td>
                      <td>
                        <div className="rounded transform  duration-200 hover:scale-110">
                          {item.nib_file.endsWith(".png") ||
                          item.nib_file.endsWith(".jpeg") ||
                          item.nib_file.endsWith(".jpg") ? (
                            <Popup
                              trigger={
                                <img
                                  src={item.nib_file}
                                  alt=""
                                  className="w-24"
                                />
                              }
                              modal
                            >
                              <img
                                src={item.nib_file}
                                alt=""
                                className="w-full"
                              />
                            </Popup>
                          ) : (
                            <div className="flex justify-center items-center">
                              <a
                                href={item.nib_file}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <BsFillFilePdfFill className="text-[30px]" />
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="grid grid-cols-2 gap-3 mt-2">
                          {item.nib_file_status === 1 && (
                            <div className="flex items-center gap-3">
                              <div>
                                <BsCheckCircleFill className="text-green-500 text-[20px]" />
                              </div>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalNibFile(true);
                                  setSipa(item.nib_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.nib_file_status === 2 && (
                            <div className="flex items-center gap-3">
                              <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                {item.nib_file_keterangan}
                              </p>
                              <FiEdit
                                className="text-[20px] hover:text-blue-500 cursor-pointer"
                                onClick={() => {
                                  setShowModalNibFile(true);
                                  setSipa(item.nib_file_keterangan);
                                }}
                              />
                            </div>
                          )}
                          {item.nib_file_status === 3 && (
                            <div className="grid grid-cols-2 gap-3">
                              <button
                                className="p-1 bg-green-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  mutateUpdateNibFile({
                                    status: 1,
                                    keterangan: "OK",
                                    company_id: companys,
                                  });
                                }}
                              >
                                Accept
                              </button>
                              <button
                                className="p-1 bg-red-500 text-white rounded-lg px-5"
                                onClick={() => {
                                  setShowModalNibFile(true);
                                  setSipa("");
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </>
              </table>
            </>
          ))
        : null}
    </div>
  );
};

export default Company;
