import React, { useEffect, useState } from "react";
import {
  terimaApotek,
  getCollector,
  getSalesman,
  getAPJ,
  getVerifyCompany,
} from "../API";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import useDebounce from "../hooks/useDebounce";

const ModalAcceptApotek = (props) => {
  const { showModal, setShowModal } = props;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [limitOrder, setLimitOrder] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [collector, setCollector] = useState("");
  const [apj, setApj] = useState("");
  const [salesman, setSalesman] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const debounceCustomerId = useDebounce(customerId, 500);

  const bm = queryParams.get("bm");
  const company = queryParams.get("company_id");

  const { data: collectorData } = useQuery({
    queryKey: ["collector"],
    queryFn: () => getCollector(bm),
  });

  const { data: apjData } = useQuery({
    queryKey: ["apj"],
    queryFn: () => getAPJ(bm),
  });

  const { data: salesmanData } = useQuery({
    queryKey: ["salesman"],
    queryFn: () => getSalesman(bm),
  });

  const { data: verifyCompanyData } = useQuery({
    queryKey: ["verify-company", debounceCustomerId],
    queryFn: () => getVerifyCompany(debounceCustomerId),
    onError: (err) => setErrorMessage(err.message.id),
    enabled: debounceCustomerId !== "",
    retry: false,
  });

  const { mutate: acceptApotek } = useMutation({
    mutationFn: terimaApotek,
    onSuccess: () => {
      setShowModal(false);
      setTimeout(() => navigate("/verify-bm-clear"), 1000);
    },
  });

  useEffect(() => {
    if (customerId === "") setErrorMessage("");
  }, [customerId]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                {/*footer*/}

                <p className="text-center font-medium text-[20px] mb-3 p-5">
                  Apakah anda yakin ingin menerima Apotek ini?
                </p>

                <p className="text-center my-3 mt-4 font-medium">
                  Kode Saleman
                </p>
                {salesmanData && salesmanData !== undefined ? (
                  <>
                    <select
                      className="select select-bordered"
                      onChange={(e) => setSalesman(Number(e.target.value))}
                      value={salesman}
                    >
                      <option value={""} className="hidden"></option>
                      {salesmanData.map((item, index) => (
                        <option key={index} value={item.salesman_id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}

                <p className="text-center my-3 mt-4 font-medium">
                  Kode Kolektor
                </p>
                {collectorData && collectorData !== undefined ? (
                  <>
                    <select
                      className="select select-bordered"
                      onChange={(e) => setCollector(e.target.value)}
                      value={collector}
                    >
                      <option value={""} className="hidden"></option>
                      {collectorData.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                      "{" "}
                    </select>
                  </>
                ) : null}

                <p className="text-center my-3 mt-4 font-medium">Kode APJ</p>
                {apjData && apjData !== undefined ? (
                  <>
                    <select
                      className="select select-bordered"
                      onChange={(e) => setApj(Number(e.target.value))}
                      value={apj}
                    >
                      <option value={""} className="hidden"></option>
                      {apjData.map((item, index) => (
                        <option key={index} value={item.bm_id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}
                <p className="text-center my-3 mt-5 font-medium">
                  Search Customer Id
                </p>
                <input
                  className="input input-bordered "
                  onChange={(e) => setCustomerId(e.target.value)}
                />
                {verifyCompanyData && verifyCompanyData !== undefined ? (
                  <div className="border-2 border-green-500 rounded-md p-3 mt-3">
                    <p className="text-center">{verifyCompanyData}</p>
                  </div>
                ) : null}

                {Boolean(errorMessage) ? (
                  <div className="border-2 border-red-500 rounded-md p-3 mt-3">
                    <p className="text-center">{errorMessage}</p>
                  </div>
                ) : null}
                <p className="text-center my-3 mt-5 font-medium">
                  Limit Order Apotek
                </p>
                <input
                  placeholder="Masukan Limit order"
                  className="input input-bordered "
                  onChange={(e) => setLimitOrder(Number(e.target.value))}
                />
                <div className="flex items-center justify-center p-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 disabled:bg-gray-200 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={Boolean(errorMessage)}
                    onClick={() =>
                      acceptApotek({
                        status: 1,
                        company_id: company,
                        sales_id: salesman,
                        collector_id: collector,
                        pharmacist_id: apj,
                        bm_id: Number(bm),
                        limit_order: limitOrder,
                        customer_id: customerId,
                      })
                    }
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalAcceptApotek;
