import React, { useRef, useState, useEffect } from "react";
import { FaFilePdf } from "react-icons/fa";
import { FcPrint } from "react-icons/fc";
import { useReactToPrint } from "react-to-print";
import { useQuery, useMutation } from "@tanstack/react-query";
import Popup from "reactjs-popup";
import "./User.css";
import "reactjs-popup/dist/index.css";
import { Document, Page, pdfjs } from "react-pdf";
import Logo from "../assets/olinblackcircle.png";
import { Link } from "react-router-dom";
import { GetPublicToken } from "../utils/GeneratePublicToken";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { acceptCompaniesExisting, rejectCompaniesExisting } from "./API";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const url = "https://cors-anywhere.herokuapp.com/";

const VerifyBM = () => {
  const [numpage, setNumPage] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState("");
  const [isAcceptApotekModalOpen, setIsAcceptApotekModalOpen] = useState(false);
  const [isRejectApotekModalOpen, setIsRejectApotekModalOpen] = useState(false);
  const [reasonAccept, setReasonAccept] = useState("");
  const [reasonReject, setReasonReject] = useState("");
  const [branch, setBranch] = useState([]);
  const [owner, setOwner] = useState([]);
  const [staff, setStaff] = useState([]);
  const [etc, setEtc] = useState([]);
  const [company, setCompany] = useState([]);

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const companys = queryParams.get("company_id");
  const bms = queryParams.get("bm");

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  const { mutate: acceptApotek } = useMutation({
    mutationFn: acceptCompaniesExisting,
    onSuccess: () => navigate("/verify-bm-clear"),
  });

  const { mutate: rejectApotek } = useMutation({
    mutationFn: rejectCompaniesExisting,
    onSuccess: () => navigate("/verify-bm-clear"),
  });

  const fetchAllDetail = async () => {
    const TokenAuth = GetPublicToken().Authorization;
    const TokenAuthID = GetPublicToken()["Authorization-ID"];

    try {
      const config = {
        headers: {
          Authorization: TokenAuth,
          "Authorization-ID": TokenAuthID,
          "Content-Type": "application/json",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_ACCOUNT}verify/data?bm=${bms}&company_id=${companys}`,
      };

      const res = await axios(config);
      setBranch(res.data.data_branch);
      setCompany(res.data.data_company);
      setOwner(res.data.data_owner);
      setStaff(res.data.data_staff);
      setEtc(res.data.data_salesman_etc);
    } catch (err) {
      throw err;
    }
  };

  function onDocumentLoadSuccess({ numpage }) {
    setNumPage(numpage);
    // setPageNum(1);
  }

  const componentRef = useRef();

  const urlsCompanyNpwp = company.map((item) => {
    return <>{item.npwp_file}</>;
  });

  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = componentRef.current.cloneNode(true);
      const PrintElem = document.createElement("Document", "Page");
      const header = `<img src="${Logo}" alt="" class="watermark"/>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
    documentTitle: `Formulir Spesimen Data Pelanggan Modern Trade`,
  });

  useEffect(() => {
    fetchAllDetail();
  }, []);

  return (
    <div className="w-full">
      <div>
        <div className="text-sm breadcrumbs mx-[50px] ">
          <ul>
            <li className="text-blue-600">
              <Link>Verify Pendaftaran Apotek Baru</Link>
            </li>
            <li>
              <Link>Apotek Jaya</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className=" flex flex-col justify-center items-center">
        <div className=" w-full">
          <div className=" border-2 border-black border-dashed mx-[50px] mb-5 ">
            <div className="bg-slate-200 p-5 mx-20 my-10 rounded-xl shadow-xl">
              <div className="flex flex-row items-center gap-[400px]">
                <p className="ml-[80px] font-bold text-[25px] ">
                  Formulir Spesimen Pendaftaran Pelanggan Baru Modern Trade
                </p>
                <div className="flex flex-row  items-center gap-2 cursor-pointer">
                  <FcPrint className="text-[40px] my-2" />
                  <p
                    className="text-blue-600  mr-20 text-[25px] cursor-pointer"
                    onClick={handlePrint}
                  >
                    Print
                  </p>
                </div>
              </div>
              {status && (
                <div className="flex justify-end mr-[80px]">
                  {value === "Aktif" ? (
                    <p className="bg-green-500 p-2 px-6 rounded-lg text-white font-semibold">
                      {value}
                    </p>
                  ) : (
                    <p className="bg-red-500 p-2 px-6 rounded-lg text-white font-semibold">
                      {value}
                    </p>
                  )}
                </div>
              )}

              <div className="flex flex-col gap-5 justify-center mt-3 mx-20">
                <div
                  tabIndex={0}
                  className="collapse collapse-open collapse-arrow border border-base-300 bg-base-100"
                >
                  <div
                    className="collapse-title text-xl 
                  bg-primary text-white font-medium"
                  >
                    Branch Information
                  </div>
                  <div className="collapse-content ">
                    <div className="flex flex-row">
                      <div className="flex flex-col mt-2 ml-6">
                        <p className="my-1">Cabang Distributor</p>
                        <p className="my-1">Alamat Email</p>
                        <p className="my-1">Alamat</p>
                        <p className="my-1">Status Akun</p>
                        <p className="my-1">Kode Salesman</p>
                        <p className="my-1">Kode Kolektor</p>
                        <p className="my-1">APJ</p>
                        <p className="my-1">Limit Order</p>
                      </div>
                      <div className="flex flex-col mt-2 ml-[50px]">
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                      </div>

                      {branch.map((item, index) => {
                        return (
                          <div
                            className="flex flex-col justify-start ml-[20px]"
                            key={index}
                          >
                            <div className="flex flex-col mt-[9px]">
                              <p className="my-1">{item.cabang_nama}</p>
                              <p className="my-1">{item.email}</p>
                              <p className="my-1">{item.alamat}</p>
                              <p className="my-1 text-blue-500 font-bold">
                                Tidak Aktif
                              </p>

                              {etc.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <p className="my-1">{item.salesman_id}</p>
                                    <p className="my-1">{item.collector_id}</p>
                                    <p className="my-1">{item.pharmacist_id}</p>
                                    <p className="my-1">{item.limit_order}</p>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {company.map((list, index) => (
                  <div
                    tabIndex={0}
                    className="collapse collapse-open collapse-arrow border border-base-300 bg-base-100"
                    key={index}
                  >
                    <div className="collapse-title text-xl bg-primary text-white font-medium">
                      Company Information
                    </div>

                    <div className="collapse-content flex flex-row">
                      <div className="flex flex-col mt-2 ml-6">
                        <p className="my-1">Bidang Usaha</p>
                        <p className="my-1">Company Name</p>
                        <p className="my-1">Alamat Sesuai NPWP</p>
                        <p className="my-1">Alamat Usaha</p>
                        <p className="my-1">No HP Sarana</p>
                        <p className="my-1">NIB</p>
                        <p className="my-1">Kode SIPNAP</p>
                        <p className="my-1">Surat Izin Usaha</p>
                        <p className="my-1">Masa Berlaku Surat Izin</p>
                        <p className="my-1">No Rekening Apotek</p>
                        <p className="my-1">Kode Bank Address</p>
                        <p className="my-1">Nama Pemilik Rekening</p>
                        <p className="font-bold my-6">Kelengkapan Dokumen</p>
                      </div>
                      <div className="flex flex-col  mt-2 ml-[50px]">
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                        <p className="my-1">:</p>
                      </div>
                      <div className="flex flex-col justify-start ml-[20px]">
                        <div className="flex flex-col mt-[9px]" key={list.ID}>
                          <p className="my-1">{list.bidang_usahaa}</p>
                          <p className="my-1">{list.nama_perusahaan}</p>
                          <p className="my-1">{list.alamat_npwp}</p>
                          <p className="my-1">{list.alamat_sarana}</p>
                          <p className="my-1">{list.telepon}</p>
                          <p className="my-1">{list.nib}</p>
                          <p className="my-1">{list.kode_sipnap}</p>
                          <p className="my-1">{list.surat_izin_usaha_sarana}</p>
                          <p className="my-1">{list.masa_berlaku_surat_izin}</p>
                          <p className="my-1">{list.no_rekening}</p>
                          <p className="my-1">{list.bank}</p>
                          <p className="my-1">{list.nama_pemilik_rekening}</p>
                          <p className="my-6"> </p>
                          <p className="mt-2"> </p>
                        </div>
                      </div>
                    </div>

                    <div className="ml-[50px]">
                      <div className="flex flex-col items-start gap-14">
                        <table className="table">
                          <tbody>
                            <tr className="">
                              <th className="">NPWP Perusahaan</th>
                              <td>:</td>
                              <td>
                                <div className="avatar">
                                  <div className="w-24 rounded">
                                    {isImage(urlsCompanyNpwp) ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={list.npwp_file}
                                            alt="npwp"
                                          />
                                        }
                                        modal
                                        position="right bottom"
                                      >
                                        <div>
                                          <img
                                            src={list.npwp_file}
                                            alt="npwp"
                                            className="w-[500px] ml-[100px]"
                                          />
                                        </div>
                                      </Popup>
                                    ) : (
                                      <a href={list.npwp_file} target="_blank">
                                        <FaFilePdf className="absolute z-20" />
                                        {/* <iframe
                                          src={list.npwp_file}
                                          className="w-full"
                                        ></iframe> */}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className="">
                              <th className="">NIB</th>
                              <td>:</td>
                              <td>
                                <div className="avatar">
                                  <div className="w-24 rounded">
                                    {isImage ? (
                                      <Popup
                                        trigger={
                                          <img src={list.nib_file} alt="npwp" />
                                        }
                                        modal
                                        position="right bottom"
                                      >
                                        <div>
                                          <img
                                            src={list.nib_file}
                                            alt="npwp"
                                            className="w-[500px] ml-[100px]"
                                          />
                                        </div>
                                      </Popup>
                                    ) : (
                                      <a href={list.nib_file} target="_blank">
                                        <FaFilePdf className="absolute z-20" />
                                        {/* <iframe
                                          src={list.nib_file}
                                          className="w-full"
                                        ></iframe> */}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className="">
                              <th className="">Izin Sarana</th>
                              <td>:</td>
                              <td>
                                <div className="avatar">
                                  <div className="w-24 rounded">
                                    {isImage ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={list.sius_file}
                                            alt="npwp"
                                          />
                                        }
                                        modal
                                        position="right bottom"
                                      >
                                        <div>
                                          <img
                                            src={list.sius_file}
                                            alt="npwp"
                                            className="w-[500px] ml-[100px]"
                                          />
                                        </div>
                                      </Popup>
                                    ) : (
                                      <a href={list.sius_file} target="_blank">
                                        <FaFilePdf className="absolute z-20" />
                                        {/* <iframe
                                          src={list.sius_file}
                                          className="w-full"
                                        ></iframe> */}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className="">
                              <th className="">Stempel Perusahaan</th>
                              <td>:</td>
                              <td>
                                <div className="avatar">
                                  <div className="w-24 rounded">
                                    {isImage ? (
                                      <Popup
                                        trigger={
                                          <img
                                            src={list.stempel_file_sarana}
                                            alt="npwp"
                                          />
                                        }
                                        modal
                                        position="right bottom"
                                      >
                                        <div>
                                          <img
                                            src={list.stempel_file_sarana}
                                            alt="npwp"
                                            className="w-[500px] ml-[100px]"
                                          />
                                        </div>
                                      </Popup>
                                    ) : (
                                      <a
                                        href={list.stempel_file_sarana}
                                        target="_blank"
                                      >
                                        <FaFilePdf className="absolute z-20" />
                                        {/* <iframe
                                          src={list.stempel_file_sarana}
                                          className="w-full"
                                        ></iframe> */}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}

                {owner.map((item, index) => {
                  return (
                    <div
                      tabIndex={0}
                      className="collapse collapse-arrow collapse-open border border-base-300 bg-base-100"
                      key={index}
                    >
                      <div className="collapse-title text-xl bg-primary text-white font-medium">
                        Owner Information
                      </div>
                      <div className="collapse-content ">
                        <div className="flex flex-row">
                          <div className="flex flex-col mt-2 ml-6">
                            <p className="my-1">Nama Pemilik Sarana</p>
                            <p className="my-1">ID Card Pemilik Sarana</p>
                            <p className="my-1">No Handphone Pemilik Sarana</p>
                            <p className="my-1">No NPWP Pemilik Sarana</p>
                            <p className="my-1">Email Pemilik Sarana</p>
                          </div>
                          <div className="flex flex-col mt-2 ml-[50px]">
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                            <p className="my-1">:</p>
                          </div>

                          <div className="flex flex-col justify-start ml-[20px]">
                            <div className="flex flex-col mt-[9px]">
                              <p className="my-1">{item.owner_name}</p>
                              <p className="my-1">{item.idcard}</p>
                              <p className="my-1">{item.phone}</p>
                              <p className="my-1">{item.npwp}</p>
                              <p className="my-1">{item.email}</p>
                              <p className="my-6"> </p>
                              <p className="mt-2"> </p>
                            </div>
                          </div>
                        </div>
                        <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
                          <div className="collapse-title text-xl font-medium">
                            Kelengkapan Dokumen
                          </div>
                          <div className="collapse-content">
                            <div className="ml-[50px]">
                              <div className="flex flex-col items-start gap-14">
                                <table className="table">
                                  <tbody>
                                    <tr className="">
                                      <th className="">KTP</th>
                                      <td>:</td>
                                      <td>
                                        <div className="avatar">
                                          <div className="w-24 rounded">
                                            {isImage ? (
                                              <Popup
                                                trigger={
                                                  <img
                                                    src={item.ktp_file}
                                                    alt="npwp"
                                                  />
                                                }
                                                modal
                                                position="right bottom"
                                              >
                                                <div>
                                                  <img
                                                    src={item.ktp_file}
                                                    alt="npwp"
                                                    className="w-[500px] ml-[100px]"
                                                  />
                                                </div>
                                              </Popup>
                                            ) : (
                                              <a
                                                href={item.ktp_file}
                                                target="_blank"
                                              >
                                                <FaFilePdf className="absolute z-20" />
                                                {/* <iframe
                                                  src={item.ktp_file}
                                                  className="w-full"
                                                ></iframe> */}
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>NPWP</th>
                                      <td>:</td>
                                      <td>
                                        <div className="avatar">
                                          <div className="w-24 rounded">
                                            {isImage ? (
                                              <Popup
                                                trigger={
                                                  <img
                                                    src={item.npwp_file}
                                                    alt="npwp"
                                                  />
                                                }
                                                modal
                                                position="right bottom"
                                              >
                                                <div>
                                                  <img
                                                    src={item.npwp_file}
                                                    alt="npwp"
                                                    className="w-[500px] ml-[100px]"
                                                  />
                                                </div>
                                              </Popup>
                                            ) : (
                                              <a
                                                href={item.npwp_file}
                                                target="_blank"
                                              >
                                                <FaFilePdf className="absolute z-20" />
                                                {/* <iframe
                                                  src={item.npwp_file}
                                                  className="w-full"
                                                ></iframe> */}
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="collapse  collapse-arrow">
                  <input type="checkbox" className="peer" />
                  <div className="collapse-title bg-primary peer-checked:bg-primary peer-checked:text-secondary-content text-xl  text-white font-medium">
                    Staff Information
                  </div>
                  <div className="collapse-content bg-primary text-primary-content peer-checked:bg-white  peer-checked:text-secondary-content ">
                    {staff.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="collapse my-5">
                            <input
                              type="checkbox"
                              className="peer text-black"
                            />

                            <div className="collapse-title bg-primary  peer-checked:bg-primary  peer-checked:text-secondary-content">
                              {item.nama_pekerja}
                            </div>
                            <div className="collapse-content bg-primary text-primary-content peer-checked:bg-white peer-checked:text-secondary-content">
                              <div className="flex flex-row items-center">
                                <div className="flex flex-col text-black">
                                  <p className="my-1">Nama Pekerja</p>
                                  <p className="my-1">Jenis Staff</p>
                                  <p className="my-1">No SIPA</p>
                                  <p className="my-1">Masa Berlaku SIPA</p>
                                  <p className="my-1">No HP Apoteker</p>
                                  <p className="my-1">Alamat Email Apoteker</p>
                                </div>

                                <div className="flex flex-col text-black  ml-[50px]">
                                  <p className="my-1">:</p>
                                  <p className="my-1">:</p>
                                  <p className="my-1">:</p>
                                  <p className="my-1">:</p>
                                  <p className="my-1">:</p>
                                  <p className="my-1">:</p>
                                </div>

                                <div className="flex flex-col text-black ml-16">
                                  <p className="my-1">{item.nama_pekerja}</p>
                                  <p className="my-1">{item.jenis_pekerja}</p>
                                  <p className="my-1">{item.no_sipa}</p>
                                  <p className="my-1">{item.expired_sipa}</p>
                                  <p className="my-1">{item.telepon_staff}</p>
                                  <p className="my-1">{item.email}</p>
                                </div>
                              </div>
                            </div>

                            <div
                              tabIndex={0}
                              className="collapse collapse-arrow border border-base-300 bg-base-100 "
                            >
                              <div className="collapse-title text-xl font-medium text-black">{`Kelengkapan Dokumen ${item.nama_pekerja}`}</div>
                              <div className="collapse-content text-black">
                                <div className="ml-[50px]">
                                  <div className="flex flex-col items-start gap-14">
                                    <table className="table">
                                      <tbody>
                                        <tr className="">
                                          <th className="">KTP</th>
                                          <td>:</td>
                                          <td>
                                            <div className="avatar">
                                              <div className="w-24 rounded">
                                                {isImage ? (
                                                  <Popup
                                                    trigger={
                                                      <img
                                                        src={item.ktp_file}
                                                        alt="npwp"
                                                      />
                                                    }
                                                    modal
                                                    position="right bottom"
                                                  >
                                                    <div>
                                                      <img
                                                        src={item.ktp_file}
                                                        alt="npwp"
                                                        className="w-[500px] ml-[100px]"
                                                      />
                                                    </div>
                                                  </Popup>
                                                ) : (
                                                  <a
                                                    href={item.ktp_file}
                                                    target="_blank"
                                                  >
                                                    <FaFilePdf className="absolute z-20" />
                                                    {/* <iframe
                                                      src={item.ktp_file}
                                                      className="w-full"
                                                    ></iframe> */}
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="">
                                          <th className="">
                                            Surat Izin Praktek Apoteker/Tenaga
                                            Teknis
                                          </th>
                                          <td>:</td>
                                          <td>
                                            <div className="avatar">
                                              <div className="w-24 rounded">
                                                {isImage ? (
                                                  <Popup
                                                    trigger={
                                                      <img
                                                        src={item.sipa_file}
                                                        alt="npwp"
                                                      />
                                                    }
                                                    modal
                                                    position="right bottom"
                                                  >
                                                    <div>
                                                      <img
                                                        src={item.sipa_file}
                                                        alt="npwp"
                                                        className="w-[500px] ml-[100px]"
                                                      />
                                                    </div>
                                                  </Popup>
                                                ) : (
                                                  <a
                                                    href={item.ktp_file}
                                                    target="_blank"
                                                  >
                                                    <FaFilePdf className="absolute z-20" />
                                                    {/* <iframe
                                                      src={item.ktp_file}
                                                      className="w-full"
                                                    ></iframe> */}
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Upload Spesimen Tanda Tangan</th>
                                          <td>:</td>
                                          <td>
                                            <div className="avatar">
                                              <div className="w-24 rounded">
                                                {isImage ? (
                                                  <Popup
                                                    trigger={
                                                      <img
                                                        src={item.tanda_tangan}
                                                        alt="tanda tangan"
                                                      />
                                                    }
                                                    modal
                                                    position="right bottom"
                                                  >
                                                    <div>
                                                      <img
                                                        src={item.tanda_tangan}
                                                        alt="tanda tangan"
                                                        className="w-[500px] ml-[100px]"
                                                      />
                                                    </div>
                                                  </Popup>
                                                ) : (
                                                  <a
                                                    href={item.tanda_tangan}
                                                    target="_blank"
                                                  >
                                                    <FaFilePdf className="absolute z-20" />
                                                    {/* <iframe
                                                      src={item.tanda_tangan}
                                                      className="w-full"
                                                    ></iframe> */}
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={componentRef}
              id="item-container"
              className="sarana-print mt-20 flex flex-col "
              style={{ margin: "0", padding: "0" }}
            >
              <p className="ml-2 font-bold">
                Formulir Spesimen Data Pelanggan Modern Trade
              </p>
              <div className=" flex flex-col p-2 mb-5 justify-center page-break">
                {branch.map((item, index) => {
                  return (
                    <table
                      className="border-separate border border-slate-500"
                      key={index}
                    >
                      <thead>
                        <tr className="flex text-center font-bold items-center">
                          Branch
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-slate-500">
                            Cabang Distributor
                          </td>
                          <td className="border border-slate-500">
                            {item.cabang_nama}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Alamat Email
                          </td>
                          <td className="border border-slate-500">
                            {item.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">Alamat</td>
                          <td className="border border-slate-500">
                            {item.alamat}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Status Akun
                          </td>
                          <td className="border border-slate-500 text-blue-500 font-bold">
                            Tidak Aktif
                          </td>
                        </tr>
                        {etc.map((item) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="border border-slate-500">
                                  Kode Salesman
                                </td>
                                <td className="border border-slate-500">
                                  {item.salesman_id}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Kode Kolektor
                                </td>
                                <td className="border border-slate-500">
                                  {item.collector_id}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">APJ</td>
                                <td className="border border-slate-500">
                                  {item.pharmacist_id}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Limit Order
                                </td>
                                <td className="border border-slate-500">
                                  {item.limit_order}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                        {/* SARANA */}

                        <tr className=" flex text-center font-bold items-center mt-5">
                          Sarana
                        </tr>

                        {company.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td className="border border-slate-500">
                                  Bidang Usaha
                                </td>
                                <td className="border border-slate-500">
                                  {item.bidang_usahaa}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Company Name
                                </td>
                                <td className="border border-slate-500">
                                  {item.nama_perusahaan}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Alamat Sesuai NPWP
                                </td>
                                <td className="border border-slate-500">
                                  {item.alamat_npwp}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Alamat Usaha
                                </td>
                                <td className="border border-slate-500">
                                  {item.alamat_sarana}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  No HP Sarana
                                </td>
                                <td className="border border-slate-500">
                                  {item.telepon}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">NIB</td>
                                <td className="border border-slate-500">
                                  {item.nib}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Kode E-report/Kode SIPNAP
                                </td>
                                <td className="border border-slate-500">
                                  {item.kode_sipnap}
                                </td>
                              </tr>

                              <tr>
                                <td className="border border-slate-500">
                                  Masa Berlaku Surat Izin
                                </td>
                                <td className="border border-slate-500">
                                  {item.masa_berlaku_surat_izin}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  No Rekening Apotek
                                </td>
                                <td className="border border-slate-500">
                                  {item.no_rekening}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Bank Account
                                </td>
                                <td className="border border-slate-500">
                                  {item.bank}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-slate-500">
                                  Nama Pemilik Rekening
                                </td>
                                <td className="border border-slate-500">
                                  {item.nama_pemilik_rekening}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  );
                })}
              </div>

              {company.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="sarana-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Sarana - {}
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          NPWP Perusahaan
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage(urlsCompanyNpwp[0].props.children) ? (
                            <img src={item.npwp_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.npwp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              {Array.from(new Array(pageNum), (el, index) => (
                                <Page
                                  key={index}
                                  // height="900"
                                  pageNumber={index + 1}
                                  // renderMode="svg"
                                />
                              ))}
                            </Document>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="sarana-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Sarana - Apotek Tanah Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          NIB
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img src={item.nib_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.nib_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              {Array.from(new Array(pageNum), (el, index) => (
                                <Page
                                  key={index}
                                  height="900"
                                  pageNumber={index + 1}
                                  renderMode="svg"
                                />
                              ))}
                            </Document>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="sarana-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Sarana - Apotek Tanah Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          Izin Sarana
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img src={item.sius_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.sius_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="sarana-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Sarana - Apotek Tanah Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          Stempel Sarana
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img
                              src={item.stempel_file_sarana}
                              alt="npwp"
                              className=""
                            />
                          ) : (
                            <Document
                              file={item.stempel_file_sarana}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
              {/*OWNER*/}

              {owner.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="page-break flex flex-col p-2 mb-5 justify-center">
                      <table className="border-separate border border-slate-500">
                        <thead>
                          <tr className="flex text-center font-bold items-center">
                            Owner Information
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border border-slate-500">
                              Nama Pemilik Sarana
                            </td>
                            <td className="border border-slate-500">
                              {item.owner_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              ID Card Pemilik
                            </td>
                            <td className="border border-slate-500">
                              {item.idcard}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              No NPWP Pemilik Sarana
                            </td>
                            <td className="border border-slate-500">
                              {item.npwp}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              No Handphone Pemilik Rekening
                            </td>
                            <td className="border border-slate-500 text-blue-500 font-bold">
                              {item.phone}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              Email Pemilik Sarana
                            </td>
                            <td className="border border-slate-500 text-blue-500 font-bold">
                              {item.email}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="owner-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Owner - Apotek Tanah Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          KTP
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img src={item.ktp_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.ktp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                          {/* <img src={NIB} alt="npwp" className="" /> */}
                        </div>
                      </div>
                    </div>

                    <div
                      className="owner-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - Owner - Apotek Tanah Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          NPWP
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {/* <Document file={SamplePdf} options={{ workerSrc: '/pdf.worker.js' }} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
                      {Array.from(new Array(numpage), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      ))}
                    </Document> */}
                          {isImage ? (
                            <img src={item.npwp_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.npwp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                          {/* <Document file={SamplePdf} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
                            <Page height="900" pageNumber={pageNum} />/
                          </Document> */}

                          {/* <Document file={SamplePdf} onLoadSuccess={({ numpage }) => setNumPage(numpage)} renderMode="svg">
                      {Array.apply(null, Array(numpage))
                        .map((x, i) => i + 1)
                        .map((page) => (
                          <Page pageNumber={page} />
                        ))}
                    </Document> */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}

              {staff.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="flex flex-col mb-8" id="item-container">
                      <div className="page-break break-after-all flex flex-col p-2 mb-5 justify-center">
                        <table className="border-separate border border-slate-500 page-break">
                          <thead>
                            <tr className="flex text-center font-bold items-center">
                              Staff Information
                            </tr>
                            <tr className="flex text-center font-bold items-center mt-2">
                              {item.nama_pekerja}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border border-slate-500">
                                Jenis Pekerja
                              </td>
                              <td className="border border-slate-500">
                                {item.jenis_pekerja}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-slate-500">
                                Nama Pekerja
                              </td>
                              <td className="border border-slate-500">
                                {item.nama_pekerja}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-slate-500">
                                No SIPA
                              </td>
                              <td className="border border-slate-500">
                                {item.no_sipa}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-slate-500">
                                Masa Berlaku SIPA
                              </td>
                              <td className="border border-slate-500 ">
                                {item.expired_sipa}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-slate-500">
                                No HP Apoteker
                              </td>
                              <td className="border border-slate-500">
                                {item.telepon_staff}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-slate-500">
                                Alamat Email Apoteker
                              </td>
                              <td className="border border-slate-500">
                                {item.email}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="staff-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - {item.nama_pekerja}{" "}
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          KTP
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img src={item.ktp_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.ktp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}

                          {/* <img src={NIB} alt="npwp" className="" /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="staff-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data -{item.nama_pekerja} - Apotek Tanah
                        Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          Surat Izin Praktek Apoteker/Tenaga Teknis
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img src={item.sipa_file} alt="npwp" className="" />
                          ) : (
                            <Document
                              file={item.sipa_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                          {/* <img src={Foto} alt="npwp" className="" /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="staff-print flex flex-col justify-center items-center text-center"
                      id="item-container"
                    >
                      <p className=" font-bold text-center text-[30px]">
                        Kelengkapan data - {item.nama_pekerja} - Apotek Tanah
                        Kusir
                      </p>
                      <div className="page-break">
                        <p className="font-bold text-[20px] text-slate-500">
                          Upload Spesimen Tanda Tangan
                        </p>
                        <div className="flex justify-center mt-[60px]">
                          {isImage ? (
                            <img
                              src={item.tanda_tangan}
                              alt="npwp"
                              className=""
                            />
                          ) : (
                            <Document
                              file={item.tanda_tangan}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                height="900"
                                pageNumber={pageNum}
                                renderMode="svg"
                              />
                            </Document>
                          )}
                          {/* <img src={NPWP} alt="npwp" className="" /> */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            {isAcceptApotekModalOpen ? (
              <div
                className="relative z-50"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                              className="h-6 w-6 text-blue-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                              className="text-base font-semibold leading-6 text-gray-900"
                              id="modal-title"
                            >
                              Verifikasi
                            </h3>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Apakah Anda yakin ingin menerima apotek ini?
                              </p>
                            </div>
                            <div className="text-center mt-3">
                              <textarea
                                className="textarea textarea-bordered"
                                cols={50}
                                rows={7}
                                placeholder="Masukan Alasan disini"
                                onChange={(e) =>
                                  setReasonAccept(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-500 sm:ml-3 sm:w-auto"
                          onClick={() =>
                            acceptApotek({
                              company_id: companys,
                              reason: reasonAccept,
                            })
                          }
                        >
                          Kirim
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 sm:ml-3 sm:w-auto"
                          onClick={() => setIsAcceptApotekModalOpen(false)}
                        >
                          Batal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {isRejectApotekModalOpen ? (
              <div
                className="relative z-50"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                              className="h-6 w-6 text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                              className="text-base font-semibold leading-6 text-gray-900"
                              id="modal-title"
                            >
                              Reject Field
                            </h3>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Apakah Anda yakin ingin menolak apotek ini?
                              </p>
                            </div>
                            <div className="text-center mt-3">
                              <textarea
                                className="textarea textarea-bordered"
                                cols={50}
                                rows={7}
                                placeholder="Masukan Alasan disini"
                                onChange={(e) =>
                                  setReasonReject(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:ml-3 sm:w-auto"
                          onClick={() =>
                            rejectApotek({
                              company_id: companys,
                              reason: reasonReject,
                            })
                          }
                        >
                          Kirim
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 sm:ml-3 sm:w-auto"
                          onClick={() => setIsRejectApotekModalOpen(false)}
                        >
                          Batal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {show ? (
              <>
                <div className="flex justify-center gap-10">
                  <button
                    className="btn btn-error btn-wide mb-5 text-white hover:btn-secondary"
                    onClick={() => setIsRejectApotekModalOpen(true)}
                  >
                    Tolak Apotek
                  </button>
                  <button
                    className="btn btn-primary btn-wide mb-5"
                    onClick={() => setIsAcceptApotekModalOpen(true)}
                  >
                    Terima Apotek
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyBM;
