import axios from "axios";
import { GetPublicToken } from "../utils/GeneratePublicToken";

const TokenAuth = () => GetPublicToken().Authorization;
const TokenAuthID = () => GetPublicToken()["Authorization-ID"];

// export const getCollector = async (bms) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: TokenAuth(),
//         "Authorization-ID": TokenAuthID(),
//       },
//
//       url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/newcollector?branch_manager_id=${bms}`,
//       method: "GET",
//     };
//
//     const res = await axios(config);
//     return res.data.detail;
//   } catch (err) {
//     throw err;
//   }
// };

export const getCollector = async (bms) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/newcollector?branch_manager_id=${bms}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getSalesman = async (bms) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/salesman?branch_manager_id=${bms}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getAPJ = async (bms) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/pharmacist?branch_manager_id=${bms}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getAllBm = async () => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const switchBranch = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/change`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const rejectApotek = async (status, companys) => {
  try {
    const body = {
      status: status,
    };

    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/reject/companies?company_id=${companys}`,
      data: body,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const terimaApotek = async ({
  status,
  company_id,
  sales_id,
  collector_id,
  pharmacist_id,
  bm_id,
  limit_order,
  customer_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/update/companies`,
      data: {
        sales_id: sales_id,
        collector_id: collector_id,
        pharmacist_id: pharmacist_id,
        company_id: company_id,
        bm_id: bm_id,
        status: status,
        limit_order: limit_order,
        customer_id: customer_id,
      },
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailApotek = async (bms, companys) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      url: `${process.env.REACT_APP_API_ACCOUNT}verify/data?bm=${bms}&company_id=${companys}`,
      method: "GET",
    };

    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const putCompanyNIB = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/nib/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanySius = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/siu/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanyExpiredSius = async ({
  status,
  keterangan,
  company_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/expsiu/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanyNPWP = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/npwpfile/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanyIzin = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/siufile/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanyStempel = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/stempel/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putCompanyNibFile = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/nibfile/sarana?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putOwnerIDCard = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/idcard/owner?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putOwnerNpwp = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/npwp/owner?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putOwnerNpwpFile = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/npwpfile/owner?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putOwnerKtpFile = async ({ status, keterangan, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/ktpfile/owner?company_id=${company_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putStaffSipa = async ({
  status,
  keterangan,
  company_id,
  staff_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/sipa/staff?company_id=${company_id}&staff_id=${staff_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putStaffExpSipa = async ({
  status,
  keterangan,
  company_id,
  staff_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/expsipa/staff?company_id=${company_id}&staff_id=${staff_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putStaffKtpFile = async ({
  status,
  keterangan,
  company_id,
  staff_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/ktpfile/staff?company_id=${company_id}&staff_id=${staff_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putStaffSipaFile = async ({
  status,
  keterangan,
  company_id,
  staff_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/sipafile/staff?company_id=${company_id}&staff_id=${staff_id}`,
      data: {
        status: status,
        keterangan: keterangan,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const putStaffTandaTangan = async ({
  status,
  keterangan,
  company_id,
  staff_id,
}) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "PUT",
      url: `${process.env.REACT_APP_API_ACCOUNT}branchmanager/esign/staff?company_id=${company_id}&staff_id=${staff_id}`,
      data: { status: status, keterangan: keterangan },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptCompaniesExisting = async ({ reason, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}update/status/company/existing`,
      data: {
        status: 1,
        alasan: reason,
        company_id: company_id,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const rejectCompaniesExisting = async ({ reason, company_id }) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}update/status/company/existing`,
      data: {
        status: 2,
        alasan: reason,
        company_id: company_id,
      },
    };

    const res = await axios(config);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getVerifyCompany = async (customer_id) => {
  try {
    const config = {
      headers: {
        Authorization: TokenAuth(),
        "Authorization-ID": TokenAuthID(),
      },

      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}get-verify-company?customer_id=${customer_id}`,
    };

    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};