import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Popup from "reactjs-popup";
import { FiEdit } from "react-icons/fi";
import { BsPaperclip } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs";

import {
  putOwnerIDCard,
  putOwnerNpwp,
  putOwnerKtpFile,
  putOwnerNpwpFile,
} from "../API";

import ModalOwner from "../common/ModalOwner";

const Owner = (props) => {
  const { owner, companys } = props;

  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [showModalNpwp, setShowModalNpwp] = useState(false);
  const [showModalNpwpFile, setShowModalNpwpFile] = useState(false);
  const [showModalKtpFile, setShowModalKtpFile] = useState(false);

  const [sipa, setSipa] = useState("");

  const { mutate: mutateUpdateIdCard } = useMutation({
    mutationFn: putOwnerIDCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModal(false);
    },
  });

  const { mutate: mutateUpdateNpwp } = useMutation({
    mutationFn: putOwnerNpwp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNpwp(false);
    },
  });

  const { mutate: mutateUpdateNpwpFile } = useMutation({
    mutationFn: putOwnerNpwpFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNpwpFile(false);
    },
  });

  const { mutate: mutateUpdateKtpFile } = useMutation({
    mutationFn: putOwnerKtpFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacy"] });
      setShowModalNpwpFile(false);
    },
  });

  return (
    <div className="bg-white rounded-lg p-5 shadow-md">
      <h1 className="font-bold text-[20px]">Owner Information</h1>
      <div className="flex flex-wrap mt-5">
        <div className="flex flex-col mt-2">
          <p className="my-1">Nama Pemilik Sarana</p>
          <p className="my-1 mt-3">ID Card Pemilik Sarana</p>
          <p className="my-2">No Handphone Pemilik Sarana</p>
          <p className="my-1 mt-3">No NPWP Pemilik Sarana</p>
          <p className="my-3">Email Pemilik Sarana</p>
        </div>

        <div className="flex flex-col mt-2 ml-[50px]">
          <p className="my-1">:</p>
          <p className="my-1 mt-3">:</p>
          <p className="my-2">:</p>
          <p className="my-1 mt-3">:</p>
          <p className="my-2">:</p>
        </div>

        {owner && owner !== undefined
          ? owner.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ModalOwner
                    showModal={showModal}
                    setShowModal={setShowModal}
                    companys={companys}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateIdCard}
                  />
                  <ModalOwner
                    showModal={showModalNpwp}
                    setShowModal={setShowModalNpwp}
                    companys={companys}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateNpwp}
                  />

                  <div className="flex flex-col mt-2 ml-10">
                    <p className="my-1">{item.owner_name}</p>

                    <div className="flex flex-wrap justify-between items-center gap-52 mt-[7px]">
                      <p className="my-1">{item.idcard}</p>
                      {item.idcard_status === 1 && (
                        <div className="flex items-center gap-3">
                          <div>
                            <BsCheckCircleFill className="text-green-500 text-[20px]" />
                          </div>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModal(true);
                              setSipa(item.idcard_keterangan);
                            }}
                          />
                        </div>
                      )}

                      {item.idcard_status === 2 && (
                        <div className="flex items-center gap-3">
                          <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                            {item.idcard_keterangan}
                          </p>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModal(true);
                              setSipa(item.idcard_keterangan);
                            }}
                          />
                        </div>
                      )}
                      {item.idcard_status === 3 && (
                        <div className="grid grid-cols-2 gap-3">
                          <button
                            className="p-1 bg-green-500 text-white rounded-lg px-5"
                            onClick={() => {
                              mutateUpdateIdCard({
                                status: 1,
                                keterangan: "OK",
                                company_id: companys,
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="p-1 bg-red-500 text-white rounded-lg px-5"
                            onClick={() => {
                              setShowModal(true);
                              setSipa("");
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>

                    <p className="my-1">{item.phone}</p>
                    <div className="flex flex-wrap justify-between items-center gap-52 mt-2">
                      <div className="rounded transform  duration-200 hover:scale-110">
                        <p className="my-1">{item.npwp}</p>
                      </div>
                      {item.npwp_status === 1 && (
                        <div className="flex items-center gap-3">
                          <div>
                            <BsCheckCircleFill className="text-green-500 text-[20px]" />
                          </div>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalNpwp(true);
                              setSipa(item.npwp_keterangan);
                            }}
                          />
                        </div>
                      )}

                      {item.npwp_status === 2 && (
                        <div className="flex items-center gap-3">
                          <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                            {item.npwp_keterangan}
                          </p>
                          <FiEdit
                            className="text-[20px] hover:text-blue-500 cursor-pointer"
                            onClick={() => {
                              setShowModalNpwp(true);
                              setSipa(item.npwp_keterangan);
                            }}
                          />
                        </div>
                      )}
                      {item.npwp_status === 3 && (
                        <div className="grid grid-cols-2 gap-3">
                          <button
                            className="p-1 bg-green-500 text-white rounded-lg px-5"
                            onClick={() => {
                              mutateUpdateNpwp({
                                status: 1,
                                keterangan: "OK",
                                company_id: companys,
                              });
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="p-1 bg-red-500 text-white rounded-lg px-5"
                            onClick={() => {
                              setShowModalNpwp(true);
                              setSipa("");
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>

                    <p className="my-1">{item.email}</p>
                  </div>
                </React.Fragment>
              );
            })
          : null}
      </div>

      <div className="border rounded-lg p-5 mt-3">
        <h1 className=" font-bold text-[20px]">Kelengkapan Dokumen</h1>
        {owner && owner !== undefined
          ? owner.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ModalOwner
                    showModal={showModalNpwpFile}
                    setShowModal={setShowModalNpwpFile}
                    companys={companys}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateNpwpFile}
                  />
                  <ModalOwner
                    showModal={showModalKtpFile}
                    setShowModal={setShowModalKtpFile}
                    companys={companys}
                    setSipa={setSipa}
                    sipa={sipa}
                    mutateUpdateStaff={mutateUpdateKtpFile}
                  />
                  <table className="table mt-5">
                    <tbody>
                      <tr className="">
                        <th>KTP</th>
                        <td>:</td>
                        <td>
                          <div className="rounded transform  duration-200 hover:scale-110">
                            {item.ktp_file.endsWith(".pdf") ? (
                              <a href={item.ktp_file} target="_blank">
                                <div className="relative">
                                  <BsPaperclip className="absolute text-blue-500 text-[20px] right-[-20px] top-[-10px]" />
                                </div>
                              </a>
                            ) : (
                              <Popup
                                trigger={
                                  <img
                                    src={item.ktp_file}
                                    alt=""
                                    className="w-24"
                                  />
                                }
                                modal
                              >
                                <img
                                  src={item.ktp_file}
                                  alt=""
                                  className="w-max"
                                />
                              </Popup>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="grid grid-cols-2 gap-3 mt-2">
                            {item.ktp_file_status === 1 && (
                              <div className="flex items-center gap-3">
                                <div>
                                  <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                </div>
                                <FiEdit
                                  className="text-[20px] hover:text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    setShowModalKtpFile(true);
                                    setSipa(item.ktp_file_keterangan);
                                  }}
                                />
                              </div>
                            )}

                            {item.ktp_file_status === 2 && (
                              <div className="flex items-center gap-3">
                                <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                  {item.ktp_file_keterangan}
                                </p>
                                <FiEdit
                                  className="text-[20px] hover:text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    setShowModalKtpFile(true);
                                    setSipa(item.ktp_file_keterangan);
                                  }}
                                />
                              </div>
                            )}
                            {item.ktp_file_status === 3 && (
                              <div className="grid grid-cols-2 gap-3">
                                <button
                                  className="p-1 bg-green-500 text-white rounded-lg px-5"
                                  onClick={() => {
                                    mutateUpdateKtpFile({
                                      status: 1,
                                      keterangan: "OK",
                                      company_id: companys,
                                    });
                                  }}
                                >
                                  Accept
                                </button>
                                <button
                                  className="p-1 bg-red-500 text-white rounded-lg px-5"
                                  onClick={() => {
                                    setShowModalKtpFile(true);
                                    setSipa("");
                                  }}
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <th>NPWP</th>
                        <td>:</td>
                        <td>
                          <div className="rounded transform  duration-200 hover:scale-110">
                            {item.npwp_file.endsWith(".pdf") ? (
                              <a href={item.npwp_file} target="_blank">
                                <div className="relative">
                                  <BsPaperclip className="absolute text-blue-500 text-[20px] right-[-20px] top-[-10px]" />
                                </div>
                              </a>
                            ) : (
                              <Popup
                                trigger={
                                  <img
                                    src={item.npwp_file}
                                    alt=""
                                    className="w-24"
                                  />
                                }
                                modal
                              >
                                <img
                                  src={item.npwp_file}
                                  alt=""
                                  className="w-max"
                                />
                              </Popup>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="grid grid-cols-2 gap-3 mt-2">
                            {item.npwp_file_status === 1 && (
                              <div className="flex items-center gap-3">
                                <div>
                                  <BsCheckCircleFill className="text-green-500 text-[20px]" />
                                </div>
                                <FiEdit
                                  className="text-[20px] hover:text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    setShowModalNpwpFile(true);
                                    setSipa(item.npwp_file_keterangan);
                                  }}
                                />
                              </div>
                            )}

                            {item.npwp_file_status === 2 && (
                              <div className="flex items-center gap-3">
                                <p className="p-2 bg-red-500 rounded-lg font-medium text-white px-5">
                                  {item.npwp_file_keterangan}
                                </p>
                                <FiEdit
                                  className="text-[20px] hover:text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    setShowModalNpwpFile(true);
                                    setSipa(item.npwp_file_keterangan);
                                  }}
                                />
                              </div>
                            )}
                            {item.npwp_file_status === 3 && (
                              <div className="grid grid-cols-2 gap-3">
                                <button
                                  className="p-1 bg-green-500 text-white rounded-lg px-5"
                                  onClick={() => {
                                    mutateUpdateNpwpFile({
                                      status: 1,
                                      keterangan: "OK",
                                      company_id: companys,
                                    });
                                  }}
                                >
                                  Accept
                                </button>
                                <button
                                  className="p-1 bg-red-500 text-white rounded-lg px-5"
                                  onClick={() => {
                                    setShowModalNpwpFile(true);
                                    setSipa("");
                                  }}
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Owner;
