import React from "react";
import Olin from "../assets/terimaKasih.png";
import Logo from "../assets/olinfull.png";
const EndVerifyPage = () => {
  return (
    <div className="w-full h-screen bg-sky-200">
      <img src={Logo} alt="" className="w-[150px] p-3" />
      <div className="flex flex-row justify-evenly">
        <div className="flex flex-col justify-center items-center ml-20 ">
          <p className="text-blue-400 text-[100px] font-extrabold border-4 border-blue-400 px-5">Terima Kasih</p>
          <div className="text-center ">
            <p className="font-bold  text-blue-400 text-[30px] mt-5">
              Anda Telah menyelesaikan Verifikasi <br />
              Pendaftaran Apotek Baru
            </p>
          </div>
        </div>
        <div className="flex flex center">
          <img src={Olin} alt="" className="w-[500px] " />
        </div>
      </div>
    </div>
  );
};

export default EndVerifyPage;
