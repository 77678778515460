import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  getCollector,
  getSalesman,
  getAPJ,
  getAllBm,
  rejectApotek,
  getDetailApotek,
} from "./API";
import ModalSwitchBranch from "./common/ModalSwitchBranch";
import ModalAcceptApotek from "./common/ModalAcceptApotek";
import { Link } from "react-router-dom";
import Logo from "../assets/olinblackcircle.png";
import { FcPrint } from "react-icons/fc";
import Branch from "./components/Branch";
import Company from "./components/Company";
import Owner from "./components/Owner";
import Staff from "./components/Staff";
import { useNavigate } from "react-router-dom";
import PrintNewUser from "./PrintNewUser";
import { Document, Page, pdfjs } from "react-pdf";
import Popup from "reactjs-popup";
import { useReactToPrint } from "react-to-print";
import "./User.css";
import { useQuery } from "@tanstack/react-query";

const VerifyBmNewUser = () => {
  const navigate = useNavigate();
  const componentRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [showModalAccept, setShowModalAccept] = useState(false);

  const [isPharmacyAccepted, setIsPharmacyAccepted] = useState([]);
  const [collector, setCollector] = useState();
  const [salesman, setSalesman] = useState();
  const [apj, setApj] = useState();
  const [bm, setBm] = useState();
  const [branch, setBranch] = useState([]);
  const [company, setCompany] = useState([]);
  const [owner, setOwner] = useState([]);
  const [staff, setStaff] = useState([]);
  const [data, setData] = useState();

  const [acc, setAcc] = useState(false);
  const [acc1, setAcc1] = useState(false);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);
  const [acc4, setAcc4] = useState(false);
  const [acc5, setAcc5] = useState(false);
  const [acc6, setAcc6] = useState(false);
  const [acc7, setAcc7] = useState(false);
  const [acc8, setAcc8] = useState(false);
  const [acc9, setAcc9] = useState(false);
  const [acc10, setAcc10] = useState(false);
  const [acc11, setAcc11] = useState(false);
  const [acc12, setAcc12] = useState(false);
  const [acc13, setAcc13] = useState(false);
  const [acc14, setAcc14] = useState(false);
  const [acc15, setAcc15] = useState(false);

  const [numPage, setNumPage] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  const queryParams = new URLSearchParams(window.location.search);

  const bms = queryParams.get("bm");
  const companys = queryParams.get("company_id");

  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = componentRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      const header = `<img src="${Logo}" alt="" class="watermark"/>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
    documentTitle: `Formulir Spesimen Data Pelanggan Modern Trade`,
  });

  const onDocumentLoadSuccess = ({ numpage }) => {
    setNumPage(numpage);
    setPageNum(1);
  };

  const { data: newPharmacy } = useQuery({
    queryKey: ["pharmacy"],
    queryFn: () => getDetailApotek(bms, companys),
  });

  const handleGetDetail = async () => {
    const res = await getDetailApotek(bms, companys);
    setBranch(res.data_branch);
    setCompany(res.data_company);
    setStaff(res.data_staff);
    setOwner(res.data_owner);
    setData(res);
  };

  const handleRejectApotek = async () => {
    const res = await rejectApotek(2, companys);

    setTimeout(() => {
      navigate("/verify-bm-clear");
    }, 1000);
  };

  const handleGetCollector = async () => {
    const res = await getCollector(bms);
    setCollector(res);
  };

  const handleGetSalesman = async () => {
    const res = await getSalesman(bms);
    setSalesman(res);
  };

  const handleGetApj = async () => {
    const res = await getAPJ(bms);
    setApj(res);
  };

  const handleGetBM = async () => {
    const res = await getAllBm();
    setBm(res);
  };

  const validateStatus =
    data && data !== undefined
      ? data.data_owner[0]?.status_owner === "ok" &&
        data.data_company[0]?.status_company === "ok" &&
        data.data_staff[0]?.status_staff === "ok"
      : null;

  useEffect(() => {
    handleGetCollector();
    handleGetSalesman();
    handleGetApj();
    handleGetBM();
  }, []);

  useEffect(() => {
    handleGetDetail();
  }, [
    acc,
    acc1,
    acc2,
    acc3,
    acc4,
    acc5,
    acc6,
    acc7,
    acc8,
    acc9,
    acc10,
    acc11,
    acc12,
    acc13,
    acc14,
    acc15,
  ]);

  useEffect(() => {
    const filteredValuesArray = [];
    if (newPharmacy && newPharmacy !== undefined) {
      for (const item of newPharmacy.data_company) {
        for (const key in item) {
          if (key.includes("status") && typeof item[key] === "number") {
            filteredValuesArray.push(item[key]);
          }
        }
      }
      for (const item of newPharmacy.data_owner) {
        for (const key in item) {
          if (key.includes("status") && typeof item[key] === "number") {
            filteredValuesArray.push(item[key]);
          }
        }
      }
      for (const item of newPharmacy.data_staff) {
        for (const key in item) {
          if (key.includes("status") && typeof item[key] === "number") {
            filteredValuesArray.push(item[key]);
          }
        }
      }
    }
    setIsPharmacyAccepted(filteredValuesArray);
  }, [newPharmacy]);

  return (
    <div className="bg-slate-200 ">
      <div className="container mx-auto p-2">
        <div className="text-sm breadcrumbs w-full">
          <ul>
            <li className="text-blue-600">
              <Link>Verify Pendaftaran Apotek Baru</Link>
            </li>
            <li>
              <Link>Apotek Jaya</Link>
            </li>
          </ul>
        </div>

        <ModalSwitchBranch
          showModal={showModal}
          setShowModal={setShowModal}
          bm={bm}
          companys={companys}
        />

        <ModalAcceptApotek
          showModal={showModalAccept}
          setShowModal={setShowModalAccept}
          companys={companys}
          bms={bms}
          collector={collector}
          salesman={salesman}
          apj={apj}
        />

        <div className="border-2 border-black border-dashed p-3 grid grid-cols-1 gap-3">
          <div className="bg-white rounded-lg p-5 rounded-lg gap-3 shadow-md">
            <div className="flex flex-wrap items-center justify-between">
              <h1 className="font-bold text-base lg:text-[20px]">
                Formulir Spesimen Pendaftaran Pelanggan Baru Modern Trade
              </h1>
              <span className="flex items-center cursor-pointer gap-2 hover:text-blue-500 hover:underline">
                <FcPrint className="text-[40px]" />
                <button
                  className="p-2 lg:p-[12px] bg-blue-500 mb-3 px-4 lg:px-5 font-medium text-sm lg:text-base text-white rounded-xl hover:bg-blue-300"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>
            </div>
          </div>

          {/* Branch Information */}
          <Branch branch={branch} />

          {/* Company Information */}
          <Company company={newPharmacy?.data_company} companys={companys} />

          {/* Owner Information */}
          <Owner owner={newPharmacy?.data_owner} companys={companys} />

          {/* Staff Information */}
          <Staff staff={newPharmacy?.data_staff} companys={companys} />

          <div
            ref={componentRef}
            className="sarana-print mt-20 flex flex-col "
            style={{ margin: "0", padding: "0" }}
          >
            <p className="ml-2 font-bold">
              Formulir Spesimen Data Pelanggan Modern Trade
            </p>
            <div className=" flex flex-col p-2 mb-5 justify-center page-break">
              <table className="border-separate border border-slate-500">
                <thead>
                  <tr className="flex text-center font-bold items-center">
                    Branch
                  </tr>
                </thead>
                <tbody>
                  {branch?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="border border-slate-500">
                            Cabang Distributor
                          </td>
                          <td className="border border-slate-500">
                            {item.cabang_nama}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Alamat Email
                          </td>
                          <td className="border border-slate-500">
                            {item.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">Alamat</td>
                          <td className="border border-slate-500">
                            {item.alamat}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}

                  {/* SARANA */}
                  <tr className=" flex text-center font-bold items-center mt-5">
                    Sarana
                  </tr>

                  {company?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="border border-slate-500">
                            Bidang Usaha
                          </td>
                          <td className="border border-slate-500">
                            {item.bidang_usahaa}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Company Name
                          </td>
                          <td className="border border-slate-500">
                            {item.nama_perusahaan}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Alamat Sesuai NPWP
                          </td>
                          <td className="border border-slate-500">
                            {item.alamat_npwp}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Alamat Usaha
                          </td>
                          <td className="border border-slate-500">
                            {item.alamat_sarana}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            No HP Sarana
                          </td>
                          <td className="border border-slate-500">
                            {item.telepon}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">NIB</td>
                          <td className="border border-slate-500">
                            {item.nib}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Kode E-report/Kode SIPNAP
                          </td>
                          <td className="border border-slate-500">
                            {item.kode_sipnap}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Surat Izin Usaha
                          </td>
                          <td className="border border-slate-500">
                            {item.surat_izin_usaha_sarana}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Masa Berlaku Surat Izin
                          </td>
                          <td className="border border-slate-500">
                            {item.masa_berlaku_surat_izin}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            No Rekening Apotek
                          </td>
                          <td className="border border-slate-500">
                            {item.no_rekening}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Bank Account
                          </td>
                          <td className="border border-slate-500">
                            {item.bank}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Nama Pemilik Rekening
                          </td>
                          <td className="border border-slate-500">
                            {item.nama_pemilik_rekening}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/*SARANA PRINT PICTURE ONLY*/}
            {company?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="sarana-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Sarana - {item.nama_perusahaan}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        NPWP
                      </p>
                      <div className="flex justify-center">
                        {item.npwp_file.endsWith(".png") ||
                        item.npwp_file.endsWith(".jpg") ||
                        item.npwp_file.endsWith(".jpeg") ? (
                          <Popup
                            trigger={<img src={item.npwp_file} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.npwp_file}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        ) : (
                          <>
                            <Document
                              file={item.npwp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={false}
                                renderTextLayer={false}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sarana-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Sarana - {item.nama_perusahaan}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        Ijin Sarana
                      </p>
                      <div className="flex justify-center">
                        {item.nib_file.includes(".pdf") ? (
                          <>
                            <Document
                              file={item.nib_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={false}
                                renderTextLayer={false}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <Popup
                            trigger={<img src={item.nib_file} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.nib_file}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sarana-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Sarana - {item.nama_perusahaan}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        Stempel Perusahaan
                      </p>
                      <div className="flex justify-center">
                        {item.stempel_file_sarana.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.stempel_file_sarana}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={false}
                                renderTextLayer={false}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <Popup
                            trigger={
                              <img
                                src={item.stempel_file_sarana}
                                alt="Stempel"
                              />
                            }
                            modal
                          >
                            <div>
                              <img
                                src={item.stempel_file_sarana}
                                alt="Stempel"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="sarana-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Sarana - {item.nama_perusahaan}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        NIB
                      </p>
                      <div className="flex justify-center">
                        {item.sius_file.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.sius_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={false}
                                renderTextLayer={false}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <div className="flex justify-center items-center">
                            <Popup
                              trigger={
                                <img
                                  src={item.sius_file}
                                  alt="ktp"
                                  className="w-[500px] h-[600px]"
                                />
                              }
                              modal
                            >
                              <div>
                                <img src={item.sius_file} alt="ktp" />
                              </div>
                            </Popup>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {/*OWNER*/}
            <div className=" flex flex-col p-2 mb-5 justify-center page-break">
              <table className="border-separate border border-slate-500">
                <thead>
                  <tr className="flex text-center font-bold items-center">
                    Owner Information - Sarana - {company[0]?.nama_perusahaan}
                  </tr>
                </thead>
                <tbody>
                  {owner?.map((item, index) => {
                    return (
                      <div key={index} className="w-full">
                        <tr>
                          <td className="border border-slate-500">
                            Nama Pemilik Sarana
                          </td>
                          <td className="border border-slate-500">
                            {item.owner_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            ID Card Pemilik
                          </td>
                          <td className="border border-slate-500">
                            {item.idcard}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            No NPWP Pemilik Sarana
                          </td>
                          <td className="border border-slate-500">
                            {item.npwp}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            Email Pemilik Sarana
                          </td>
                          <td className="border border-slate-500 text-blue-500 font-bold">
                            {item.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-500">
                            No Handphone
                          </td>
                          <td className="border border-slate-500">
                            {item.phone}
                          </td>
                        </tr>
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {owner?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="owner-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Owner - {company[0]?.nama_perusahaan} -
                      KTP - {item.owner_name}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        KTP
                      </p>
                      <div className="flex justify-center">
                        {item.ktp_file.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.ktp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={false}
                                renderTextLayer={false}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <Popup
                            trigger={<img src={item.ktp_file} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.ktp_file}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="owner-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Owner - {company[0]?.nama_perusahaan} -{" "}
                      {item.owner_name}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        NPWP
                      </p>
                      <div className="flex justify-center">
                        {item.npwp_file.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.npwp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={true}
                                renderMode="svg"
                              />
                            </Document>
                            {/* <img src={NIB} alt="npwp" className="" /> */}
                          </>
                        ) : (
                          <Popup
                            trigger={<img src={item.npwp_file} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.npwp_file}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {staff?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="flex flex-col mb-8">
                    <div className="page-break break-after-all flex flex-col p-2 mb-5 justify-center">
                      <table className="border-separate border border-slate-500 page-break">
                        <thead>
                          <tr className="flex text-center font-bold items-center">
                            Staff Information - {item.jenis_pekerja} -{" "}
                            {company[0]?.nama_perusahaan} - {item.nama_pekerja}
                          </tr>
                          <tr className="flex text-center font-bold items-center mt-2">
                            {item.nama_pekerja}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border border-slate-500">
                              Jenis Pekerja
                            </td>
                            <td className="border border-slate-500">
                              {item.jenis_pekerja}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              Nama Pekerja
                            </td>
                            <td className="border border-slate-500">
                              {item.nama_pekerja}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">No SIPA</td>
                            <td className="border border-slate-500">
                              {item.no_sipa}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              Masa Berlaku SIPA
                            </td>
                            <td className="border border-slate-500 ">
                              {item.expired_sipa}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              No HP Apoteker
                            </td>
                            <td className="border border-slate-500">
                              {item.telepon_staff}
                            </td>
                          </tr>
                          <tr>
                            <td className="border border-slate-500">
                              Alamat Email Apoteker
                            </td>
                            <td className="border border-slate-500">
                              {item.email}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan Data - {company[0]?.nama_perusahaan} -{" "}
                      {item.jenis_pekerja} - {item.nama_pekerja}
                    </p>

                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        KTP
                      </p>
                      <div className="flex justify-center">
                        {item.ktp_file.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.ktp_file}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={true}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <Popup
                            trigger={<img src={item.ktp_file} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.ktp_file}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="staff-print flex flex-col justify-center items-center text-center">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Staff - {item.nama_pekerja}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        Surat Izin Praktek Apoteker/Tenaga Teknis
                      </p>
                      <div className="flex justify-center">
                        {item.sipa_file.endsWith(".pdf") ? (
                          <Document
                            file={item.sipa_file}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page pageNumber={1} wrap={true} renderMode="svg" />
                          </Document>
                        ) : (
                          <div className="flex justify-center items-center">
                            <Popup
                              trigger={
                                <img
                                  src={item.sipa_file}
                                  alt="sipa_file"
                                  className="w-[500px] h-[500px]"
                                />
                              }
                              modal
                            >
                              <div>
                                <img src={item.sipa_file} alt="sipa_file" />
                              </div>
                            </Popup>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="staff-print flex flex-col justify-center items-center text-center page-break">
                    <p className=" font-bold text-center text-[30px]">
                      Kelengkapan data - Staff - {item.nama_pekerja}
                    </p>
                    <div className="page-break">
                      <p className="font-bold text-[20px] text-slate-500">
                        Upload Spesimen Tanda Tangan
                      </p>
                      <div className="flex justify-center">
                        {item.tanda_tangan.endsWith(".pdf") ? (
                          <>
                            <Document
                              file={item.tanda_tangan}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={1}
                                wrap={true}
                                renderMode="svg"
                              />
                            </Document>
                          </>
                        ) : (
                          <Popup
                            trigger={<img src={item.tanda_tangan} alt="ktp" />}
                            modal
                          >
                            <div>
                              <img
                                src={item.tanda_tangan}
                                alt="ktp"
                                className="w-[500px] ml-[100px]"
                              />
                            </div>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-2 my-7 bg-white rounded-lg p-5 rounded-lg gap-3 shadow-md">
          <button
            className="p-3 bg-red-400 text-white rounded-lg font-medium shadow-md"
            onClick={() => setShowModal(true)}
          >
            ALIHKAN KE CABANG LAIN
          </button>
          <>
            {newPharmacy && newPharmacy !== undefined ? (
              <>
                {isPharmacyAccepted.every((element) => element === 1) ? (
                  <button
                    className="p-3 bg-blue-600 text-white rounded-lg shadow-md font-medium"
                    onClick={() => setShowModalAccept(true)}
                  >
                    TERIMA APOTEK
                  </button>
                ) : (
                  <>
                    {isPharmacyAccepted.every(
                      (element) => element === 3,
                    ) ? null : (
                      <button
                        className="p-3 bg-red-400 text-white rounded-lg shadow-md font-medium"
                        onClick={handleRejectApotek}
                      >
                        REJECT APOTEK
                      </button>
                    )}
                  </>
                )}
              </>
            ) : null}
          </>
        </div>
      </div>
    </div>
  );
};

export default VerifyBmNewUser;
