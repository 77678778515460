import React, { useState, useCallback } from "react";
import Pindah from "../../assets/pindahBranch.jpg";
import { switchBranch, getAllBm } from "../API";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";

export default function ModalSwitchBranch({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = queryParams.get("company_id");

  const [selectedBranch, setSelectedBranch] = useState({
    name: "",
    email: "",
    alamat: "",
    company_id: "",
  });

  const { data: bm } = useQuery({
    queryKey: ["bm"],
    queryFn: () => getAllBm(),
  });

  const { mutate: mutateSwitchBranch } = useMutation({
    mutationFn: switchBranch,
    onSuccess: () => navigate("/verify-bm-clear"),
  });

  const handleSelectChange = useCallback(
    (index) => {
      if (bm && bm !== undefined) {
        const selectedBranchName = bm[parseInt(index, 10)].name;
        const selectedBranchEmail = bm[parseInt(index, 10)].email;
        const selectedBranchAlamat = bm[parseInt(index, 10)].address;
        setSelectedBranch({
          cabang_nama: selectedBranchName,
          email: selectedBranchEmail,
          alamat: selectedBranchAlamat,
          company_id: companyId,
        });
      } else
        setSelectedBranch({
          cabang_nama: "",
          email: "",
          alamat: "",
          company_id: companyId,
        });
    },
    [bm, companyId],
  );

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                {/*body*/}
                <div className="flex justify-center p-6 flex-auto">
                  <img
                    src={Pindah}
                    alt=""
                    className=" justify-center w-[200px]"
                  />
                </div>
                {/*footer*/}

                <p className="text-center font-medium text-[20px] mb-3 p-5">
                  Apakah anda yakin ingin memindahkan ke cabang lain
                </p>

                <select
                  className="select select-bordered"
                  onChange={(e) => handleSelectChange(e.target.value)}
                >
                  {bm && bm !== undefined
                    ? bm.map((item, index) => (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>

                <div className="flex items-center justify-center p-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[70px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-[45px] py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => mutateSwitchBranch(selectedBranch)}
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
